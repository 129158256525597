import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { Context } from '../context/Context';
//css
import "../css/style.css";
import "../css/header.css";
import "../css/menu.css";

export default function Header() {
    const { page, setPage } = useContext(Context);
    $(".menu-close, .menu-opt, .menu-bg").click(function () {
        $(".menu-div").css({ "top": "-100vh" });
        $(".bg-slider").css({"left": "0vw"});
    });

    // $(".menu-close").click(function () {
    //     $(".menu-div").css({ "top": "-100vh" });
    //     $(".bg-slider").css({"left": "-100vw"});
    // });

    $(".menu-text").click(function () {
        $(".menu-div").css({ "top": "0" });
    });
    
    return (
        <>
            <div className="pdd-parent">
                <div className='header flex-align space-between'>
                    <Link to="/"><p className='logo-text pointer'>CONCEPT HANDS</p></Link>
                    <p className='menu-text pointer'>MENU</p>
                </div>
            </div>

            <div className='bg-slider'></div>

            <div className='menu-div flex-all-center'>
                <div className='menu-bg'></div>

                <div className='menu-box flex-all-center'>
                    <div>
                        <Link to="/"><div id='home' className={`menu-opt pointer ${page === "home" ? 'menu-opt-act' : 'menu-opt-deact'}`} onClick={()=>{setPage("home")}}><span>-</span>Home<span>-</span></div></Link>
                        <Link to="/works"><div id='works' className={`menu-opt pointer ${page === "works" ? 'menu-opt-act' : 'menu-opt-deact'}`} onClick={()=>{setPage("works")}}><span>-</span>Works<span>-</span></div></Link>
                        <Link to="/about"><div id='about' className={`menu-opt pointer ${page === "about" ? 'menu-opt-act' : 'menu-opt-deact'}`} onClick={()=>{setPage("about")}}><span>-</span>About<span>-</span></div></Link>
                        <Link to="/contact"><div id='contact' className={`menu-opt pointer ${page === "contact" ? 'menu-opt-act' : 'menu-opt-deact'}`} onClick={()=>{setPage("contact")}}><span>-</span>Contact<span>-</span></div></Link>
                    </div>
                </div>

                <div className='pointer menu-close'>
                    <p>Close</p>
                </div>
            </div>

        </>
    )
}
